:root {
  color-scheme: dark;
}

@font-face {
  font-family: Satoshi;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Satoshi-Regular.5cd7338f.woff2") format("woff2"), url("Satoshi-Regular.23214b96.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Satoshi-Italic.04615630.woff2") format("woff2"), url("Satoshi-Italic.7174094f.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Satoshi-Medium.5be63f72.woff2") format("woff2"), url("Satoshi-Medium.de33c0c3.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Satoshi-Bold.9042e65d.woff2") format("woff2"), url("Satoshi-Bold.d6123eb6.woff") format("woff");
}

* {
  scrollbar-width: thin;
  scrollbar-color: #5b5b5d #323232;
  color-scheme: dark;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #323232;
  border-radius: 5px;
}

::-webkit-scrollbar-track:hover {
  background-color: #383838;
}

::-webkit-scrollbar-track:active {
  background-color: #5b5b5d;
}

::-webkit-scrollbar-thumb {
  background-color: #5b5b5d;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d5d5d9;
}

::-webkit-scrollbar-thumb:active {
  background-color: #d5d5d9;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.loading-indicator {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  display: none;
}

.htmx-request > .loading-indicator {
  opacity: .5;
  display: block;
}

/*# sourceMappingURL=index.911882e9.css.map */
