@media (prefers-color-scheme: dark) {
    /* great, we're dark-mode only :) This might fix detection of some browser extensions */
}
:root {
    color-scheme: dark;
}

@font-face {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/satoshi/Satoshi-Regular.woff2') format('woff2'), url('../fonts/satoshi/Satoshi-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Satoshi';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/satoshi/Satoshi-Italic.woff2') format('woff2'), url('../fonts/satoshi/Satoshi-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/satoshi/Satoshi-Medium.woff2') format('woff2'), url('../fonts/satoshi/Satoshi-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/satoshi/Satoshi-Bold.woff2') format('woff2'), url('../fonts/satoshi/Satoshi-Bold.woff') format('woff');
}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #5b5b5d #323232;
    color-scheme: dark;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

*::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #323232;
}

*::-webkit-scrollbar-track:hover {
    background-color: #383838;
}

*::-webkit-scrollbar-track:active {
    background-color: #5b5b5d;
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #5b5b5d;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #d5d5d9;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #d5d5d9;
}

/* Hide the scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
.hide-scroll {
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */
}

.loading-indicator {
    display: none;
    opacity: 0;
    transition-duration: 150ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.htmx-request > .loading-indicator {
    display: block;
    opacity: 0.5;
}
